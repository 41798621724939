import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import EarlyAccessForm from './EarlyAccessForm'

import logo from '../assets/images/fibonacci-full.svg';
import menu from '../assets/images/menu.svg';
import close from '../assets/images/close.svg';
import chevronDown from '../assets/images/chevron-down.svg';
import chevronUp from '../assets/images/chevron-up.svg';
import RequestDemoModal from './RequestDemoModal';

const NavLink = ({ label, to, active }) => (
  <Link className={`nav-link ${active ? '-active' : ''}`} activeClassName="-active" to={to}>
    <div>{label}</div>
    <div className="indicator" />
  </Link>
);

const NestedItem = ({ children, title }) => {
  const [show, setShow] = useState(false);
  return (
    <div className={`nested-item ${show ? '-show' : '-hide'}`} onClick={() => setShow(!show)}>
      <li className="item nav-link">
        {title}
        <ul className={`${show ? '-show' : '-hide'}`}>
          {children}
        </ul>
      </li>
      <li className="nav-item-indicator">
        <img src={show ? chevronUp : chevronDown} />
      </li>
    </div>
  )
}

const Header = ({ path, showMenu, setShowMenu, showModal, setShowModal }) => {
  const [currScroll, setCurrScroll] = useState(null);
  const [open, setOpen] = useState(false);
  const [isPartnersActive, setIsPartnersActive] = useState(false);
  const [isResourceActive, setIsResourceActive] = useState(false);
  const [isSolutionActive, setIsSolutionActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    const handler = () => {
      setCurrScroll(window.pageYOffset);
    };

    const clickHandler = (event) => {
      setIsPartnersActive(false);

      const parent = document.getElementById('early-access-modal');
      const id = event.target.id;
      const child = document.getElementById(id);
      if(parent && parent.contains(child)) {
        setModalActive(true);
      } else {
        setModalActive(false);
      }
    };

    window.addEventListener('scroll', handler);
    window.addEventListener('click', clickHandler);

    return () => {
      window.removeEventListener('scroll', handler);
      window.removeEventListener('click', clickHandler);
    };
  }, []);

  useEffect(() => {
    setOpen(showMenu);
  }, [showMenu])

  useEffect(() => {
    setModalActive(showModal);
  }, [showModal])

  useEffect(() => {
    setShowMenu && setShowMenu(open);
  }, [open])

  useEffect(() => {
    setShowModal && setShowModal(modalActive);
  }, [modalActive])

  const headerClassName = `${currScroll > 32 ? '-sticky' : ''} ${open ? '-open' : ''}`;

  return (
    <header id="header" className={headerClassName}>
      <div className="main-nav">
        <div className="container">
          <Link to="/">
            <img src={logo} alt="" height="36px" />
          </Link>
          <nav className="navigation">
            <ul>
              <li className="item">
                <NavLink to="/" label="Home" />
              </li>
              <li className={`item -dropdown ${isSolutionActive ? '-active' : ''}`}>
                <button
                  className="nav-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsSolutionActive(!isSolutionActive);
                    setIsResourceActive(false);
                    setIsPartnersActive(false);
                    setModalActive(false);
                  }}
                >
                  <div className={`nav-link ${(path === '/solutions/corporate-legal-law-and-firms/' || path === '/solutions/legal-project-management/') ? '-active' : ''}`}>
                    <div>Solutions</div>
                    <div className="indicator" />
                  </div>
                  <img src={chevronDown} alt="dropdown" />
                </button>
                <div className="subnavigation">
                  <Link to="/solutions/corporate-legal-law-and-firms/" className="nav-link">
                    <div>Corporate Legal & Law firms</div>
                  </Link>
                  <Link to="/solutions/legal-project-management/" className="nav-link">
                    <div>Legal Project Management</div>
                  </Link>
                </div>
              </li>
              <li className="item">
                <NavLink to="/features/" label="Features" />
              </li>
              <li className="item">
                <NavLink to="/learn/" label="Learn" />
              </li>
              <li className="item">
                <NavLink to="/blog/" label="News" />
              </li>
              <li className={`item -dropdown ${isPartnersActive ? '-active' : ''}`}>
                <button
                  className="nav-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsPartnersActive(!isPartnersActive);
                    setModalActive(false);
                    setIsResourceActive(false);
                    setIsSolutionActive(false);
                  }}
                >
                  <div className={`nav-link ${(path === '/steering-group/' || path === '/implementation-partners/') ? '-active' : ''}`}>
                    <div>Partners</div>
                    <div className="indicator" />
                  </div>
                  <img src={chevronDown} alt="dropdown" />
                </button>
                <div className="subnavigation">
                  <Link to="/steering-group/" className="nav-link">
                    <div>Steering Group</div>
                  </Link>
                  <Link to="/implementation-partners/" className="nav-link">
                    <div>Implementation Partners</div>
                  </Link>
                </div>
              </li>
              <li id="nav-link-modal" className={`item -dropdown ${modalActive ? '-active' : ''}`}>
                <button
                  className="base-button -bold -medium"
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalActive(!modalActive);
                    setIsPartnersActive(false);
                  }}
                >
                  Request a demo
                </button>
                <RequestDemoModal />
              </li>
            </ul>
          </nav>
          {
            open ? (
              <button
                className="menu-button"
                onClick={() => setOpen(false)}
              >
                Close
                {' '}
                <img src={close} className="icon" alt="close" />
              </button>
            ) : (
                <button
                  className="menu-button"
                  onClick={() => setOpen(true)}
                >
                  Menu
                  {' '}
                  <img src={menu} className="icon" alt="menu" />
                </button>
              )
          }
        </div>
      </div>
      <div id="mobile-nav" className={`mobile-nav ${open ? '-open' : ''}`}>
        <nav className="navigation">
          <ul>
            <li className={`item ${path === '/' ? '-active' : '-inactive'}`}>
              <div className="indicator" />
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <NestedItem title="Solutions">
              <li className={`item ${path === '/solutions/corporate-legal-law-and-firms/' ? '-active' : '-inactive'}`}>
                <div className="indicator" />
                <Link className="nav-link" to="/solutions/corporate-legal-law-and-firms/">
                  Corporate Legal & Law firms
              </Link>
              </li>
              <li className={`item ${path === '/solutions/legal-project-management/' ? '-active' : '-inactive'}`}>
                <div className="indicator" />
                <Link className="nav-link" to="/solutions/legal-project-management/" >
                  Legal Project Management
              </Link>
              </li>
            </NestedItem>
            <li className={`item ${path === '/features/' ? '-active' : '-inactive'}`}>
              <div className="indicator" />
              <Link className="nav-link" to="/features/">
                Features
              </Link>
            </li>
            <li className={`item ${path === '/learn/' ? '-active' : '-inactive'}`}>
              <div className="indicator" />
              <Link className="nav-link" to="/learn/">
                Learn
              </Link>
            </li>
            <li className={`item ${path === '/blog/' ? '-active' : '-inactive'}`}>
              <div className="indicator" />
              <Link className="nav-link" to="/blog/">
                News
              </Link>
            </li>
            <NestedItem title="Partners">
              <li className={`item ${path === '/steering-group/' ? '-active' : '-inactive'}`}>
                <div className="indicator" />
                <Link className="nav-link" to="/steering-group/">
                  Steering Group
              </Link>
              </li>
              <li className={`item ${path === '/implementation-partners/' ? '-active' : '-inactive'}`}>
                <div className="indicator" />
                <Link className="nav-link" to="/implementation-partners/">
                  Implementation Partners
              </Link>
              </li>
            </NestedItem>
          </ul>
          <RequestDemoModal />
        </nav>
      </div>
    </header>
  );
};

export default Header;
