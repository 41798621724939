import React, { useRef, useState } from 'react';

import { submitForm } from '../utils/api';

import checkCircle from '../assets/images/green-check-circle.svg';

const EarlyAccessForm = ({ className = '', submitLabel, placeholder = 'Your email address...' }) => {
  const earlyAccessInput = useRef(null);
  const [notification, setNotification] = useState(null);
  const [email, setEmail] = useState('');

  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <div className="early-access-form">
      <div className={`email-form ${className}`}>
        <input
          type="text"
          placeholder={placeholder}
          ref={earlyAccessInput}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
        />
        <button
          className="submit"
          onClick={() => {
            if (re.test(String(email).toLowerCase())) {
              submitForm({ email, full_name: 'N/A', early_access: 'True' })
                .then((res) => {
                  if (res.ok) {
                    setNotification(true);
                  } else {
                    setNotification(false);
                  }
                });
            } else {
              setNotification(false);
            }
          }}
        >
          {submitLabel}
        </button>
      </div>
      <div
        className={`form-response ${notification != null ? '-visible' : ''}`}
        style={{
          display: notification == null ? 'none' : 'flex', margin: '0 auto', fontSize: '14px', marginTop: 8,
        }}
      >
        {notification && (<img src={checkCircle} className="_mr-8" alt="check icon" />)}
        <div className={`message ${notification === true ? '-success' : ''}`} style={{ color: notification ? '#1E262B' : '#CC4643' }}>
          {notification
            ? 'We\'ll be in touch soon to show off Fibonacci\'s bells and whistles'
            : 'Your email doesn\'t look right!'}
        </div>
      </div>
    </div>
  );
};

export default EarlyAccessForm;
