import React, { useEffect, useState } from 'react';
import { submitForm } from '../utils/api';
import { countries, prefferedCountries } from '../utils/countries';
import checkCircle from '../assets/images/green-check-circle.svg';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import debounce from 'lodash.debounce'

function RequestDemoModal() {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const requiredFields = ['name', 'email', 'title', 'country'];
  const partnership_type = "Demo Request";
  const [state, setState] = useState({
    name: '',
    email: '',
    title: '',
    country: ''
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    title: false,
    country: false
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (key, value) => {
    setState(state => ({
      ...state,
      [key]: value
    }))
    setErrors(errors => ({
      ...errors,
      [key]: false
    }))
  }

  const submit = async (e) => {
    if (!validation()) {
      return;
    }
    const token = await executeRecaptcha('fibonacciDemoForm')
    const params = {
      email: state.email,
      full_name: state.name,
      jobtitle: state.title,
      country: state.country,
      partnership_type: partnership_type,
      token: token
    }

    submitForm(params)
      .then((res) => {
        if (res.ok) {
          setSubmitted(true);
        } else {
          setSubmitted(false);
        }
      })
      .catch((error) => {
        setSubmitted(false)
      })
  }

  const debouncedSubmit = debounce(submit, 500)

  const validation = () => {
    let val = true;
    requiredFields.forEach(field => {
      if (state[field] === '') {
        setErrors(errors => ({
          ...errors,
          [field]: true
        }))
        val = false;
      }
    })
    return val;
  }

  return (
    <div className="early-access" id="early-access-modal">
      <h4 id="modal-title" className="modal-title"> Request a demo</h4>
      <form id="demo-video-form">
        <div className="form-group" id="name-form-group">
          <label className="label" htmlFor="full-name">Full name</label>
          <input
            onChange={(e) => handleChange('name', e.target.value)}
            className={`input ${errors.name && '-invalid'}`}
            type="text"
            id="full-name"
            placeholder="" />
        </div>
        <div className="form-group" id="email-form-group">
          <label className="label" htmlFor="work_email">Work email</label>
          <input
            onChange={(e) => handleChange('email', e.target.value)}
            className={`input ${errors.email && '-invalid'}`}
            type="email"
            id="work_email"
            placeholder="name@company.com" />
        </div>
        <div className="form-group" id="title-form-group">
          <label className="label" htmlFor="roleTitle">Role title</label>
          <input
            onChange={(e) => handleChange('title', e.target.value)}
            className={`input ${errors.title && '-invalid'}`}
            type="text"
            id="roleTitle"
            placeholder="" />
        </div>
        <div className="form-group" id="country-form-group">
          <label className="label" htmlFor="selectCountry" className="label">Country</label>
          <select id="selectCountry"
            onChange={(e) => handleChange('country', e.target.value)}
            className={`select ${errors.country && '-invalid'}`}>
            <optgroup>
              <option>Select country</option>
            </optgroup>
            <optgroup>
              {prefferedCountries.map((p, i) => (
                <option key={i}>{p}</option>
              ))}
            </optgroup>
            <optgroup>
              {countries.map((c, i) => (
                <option key={i}>{c}</option>
              ))}
            </optgroup>
          </select>
        </div>
        <div className="grecaptcha-attribution mb-24">
          This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
        <button
          id="submit-video-form"
          className="base-button -primary"
          style={{ display: "block", width: "100%", marginTop: "25px" }}
          type="button"
          onClick={debouncedSubmit}
        >
          Request demo
        </button>
      </form>
      {submitted && <div className="message -success" style={{ display: "flex" }}>
        <img src={checkCircle} style={{alignSelf: "start"}} className="_mr-8" alt="check icon" />
        Thanks for requesting a demo. We'll be in touch soon to confirm a time and day with you.
      </div>
      }
    </div>
  )
}

export default RequestDemoModal;
